import request from "@/utils/request";

export function console_login(data:any){ //工作台登录
    return request({
        baseURL:process.env.VUE_APP_SSO_BASE_API,
        url:'/connect/token',
        headers:{
            'Content-Type':'application/x-www-form-urlencoded; charset=utf-8'
        },
        method:'post',
        data
    })
}