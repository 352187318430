import type { Component } from 'vue'
import YxIcon from './yxIcon/index.vue'
import TileSelection from './TileSelection/index.vue'
import Search from './Search/index.vue'
import NoData from './NoData/index.vue'
import CustomDatePicker from './CustomDatePicker/index.vue'
import Address from './Address/index.vue'
import Bread from './Bread/index.vue'
import SingleShowMore from './SingleShowMore/index.vue'
import YxRouterView from './YxRouterView/index.vue'
import SendCode from './SendCode/index.vue'

const components:{[propName:string]:Component} = {
    YxIcon,
    TileSelection,
    Search,
    NoData,
    CustomDatePicker,
    Address,
    Bread,
    SingleShowMore,
    YxRouterView,
    SendCode
}
export default components