import { createStore } from 'vuex'
import { consoleRoutes } from '@/router'
import { cloneDeep, concat, divide, flattenDeep, isNull, uniq } from 'lodash-es'
import {get_user_info, permission_list} from '@/api/base'
import { console_login } from '@/api/sso'

const pathResolve = (parentPath: string, path: string) => {
  const childPath = path.startsWith("/") || !path ? path : `/${path}`;
  return `${parentPath}${childPath}`.replace(/\/\//g, "/");
};

let refreshTokenTime:any = null

function hasPermission(route:any,permission:Array<any> = []){
  if(route.meta&&route.meta.permission&&route.meta.permission.length>0){
    const num = route.meta.permission.reduce((sum:number,current:string)=>{
      return sum+=permission.includes(current)?1:0
    },0)
    return num>0
  }else{
    return true
  }
}

export function permissionFlat(permission:Array<any> = []){
  let res = <any>[]
  if(permission&&permission.length>0){
    permission.forEach(item=>{
      if(item.permission){
        res.push(item.permission)
      }
      if(item.children&&item.children.length>0){
        const child = permissionFlat(item.children)
        res = concat(res,child)
      }
    })
  }
  return uniq(res)
}

function filterAsyncRoutes(routes:Array<any> = [],permission:Array<any> = []) {
  const res = <any>[]
  if(routes.length>0){
    routes.forEach(route=>{
      const tmp = {...route}
      if(hasPermission(tmp,permission)){
        if(tmp.children){
          tmp.children = filterAsyncRoutes(tmp.children,permission)
        }
        res.push(tmp)
      }
    })
  }
  return res
}

function asyncRouterFlat(routes:Array<any> = []){
  routes.map(item=>{
    Object.assign(item,{
      meta:{
        parent:[{path:item.path,name:item.name}]
      }
    })
    if(item.children&&item.children.length>0){
      item.children = routerSetFlat(cloneDeep(item.children),item,[])
    }
  })
  return routes
}

function routerSetFlat(routes:Array<any> = [],parent:any,list:Array<any> = []){
  routes.map(item=>{
    item.path = pathResolve(parent.path,item.path)
    if(!item['meta']['parent']){
      item['meta']['parent'] = [].concat(parent['meta']['parent'])
    }
    item['meta']['parent'].push({path:item.path,name:item.name})
    if(item.children&&item.children.length>0){
      routerSetFlat(cloneDeep(item.children),item,list)
    }else{
      list.push(item)
    }
  })
  return list
}

interface State{
  collapse:boolean,
  isAddRoutes:boolean,
  menuList:Array<[]>,
  cacheStatus:boolean,
  token:string,
  tokenData:{
    refresh_token:'',
    expires_in:0
  },
  permission:Array<any>[],
  userInfo:{}
}

export default createStore({
  state:():State=>{
    return {
      collapse:false,//折叠侧边栏
      isAddRoutes:false,//是否已添加路由
      menuList:[],//侧边栏菜单
      cacheStatus:true,
      token:localStorage.getItem('token')||'',
      tokenData:Object.assign({
        refresh_token:'',
        expires_in:0
      },localStorage.getItem('tokenData')?JSON.parse(localStorage.getItem('tokenData') as string):{}),
      permission:[],
      userInfo:{},
    }
  },
  getters: {
    collapse:(state)=>state.collapse,
    isAddRoutes:(state)=>state.isAddRoutes,
    menuList:state=>state.menuList,
    cacheStatus:state=>state.cacheStatus,
    token:state=>state.token,
    tokenData:state=>state.tokenData,
    permission:state=>state.permission,
    userInfo:state=>state.userInfo,
    parkList:(state)=>{
      let userInfo:any = state.userInfo
      let parkList = userInfo?.userProject||[]
      return parkList
    }
  },
  mutations: {
    setCollapse(state,val){
      state.collapse = val
    },
    setIsAddRoutes(state,val=false){
      state.isAddRoutes = val
    },
    setMenuList(state,val=[]){
      state.menuList = val
    },
    setCacheStatus(state,val=false){
      state.cacheStatus = val
    },
    setToken(state,val=''){
      localStorage.setItem('token',val)
      state.token = val
    },
    setPermission(state,val=[]){
      state.permission = val
    },
    setUserInfo(state,val={}){
      state.userInfo = val
    },
    setTokenData(state,val={}){
      Object.assign(state.tokenData,val)
      localStorage.setItem('tokenData',JSON.stringify(state.tokenData))
    },
  },
  actions: {
    getUserInfo({commit}){
      return new Promise((resolve,reject)=>{
        get_user_info().then(res=>{
          if(res.status==200){
            commit('setUserInfo',res.data)
          }
        })
      })
    },
    signOut({commit}){
      return new Promise((res)=>{
        commit('setToken')
        commit('setPermission')
        commit('setMenuList')
        commit('setIsAddRoutes')
        commit('setUserInfo')
        sessionStorage.clear()
        localStorage.clear()
        res(true)
      })
    },
    generateRoutes({commit,state}):Promise<unknown>{
      return new Promise((resolve)=>{
        let accessedRouters = []
        accessedRouters = filterAsyncRoutes(consoleRoutes,permissionFlat(state.permission))
        const routesFlat = asyncRouterFlat(cloneDeep(accessedRouters))
        routesFlat.push({
          path: '/:path(.*)*',
          redirect: '/',
          meta:{
            hidden:true
          }
        })
        commit('setMenuList',accessedRouters)
        resolve(routesFlat)
      })
    },
    getPermission({commit}):Promise<unknown>{
      return new Promise((resolve)=>{
        permission_list().then(res=>{
          if(res.status==200){
            const data = res.data.items
            commit('setPermission',data)
            resolve(res)
          }
        })
      })
    },
    resetToken({commit,state,dispatch}):Promise<unknown>{
      return new Promise((resolve)=>{
        console_login({
          client_id:process.env.VUE_APP_CLIENTID,
          grant_type:'refresh_token',
          refresh_token:state.tokenData.refresh_token
        }).then(res=>{
          if(res.status==200){
            commit('setToken',`${res.data.token_type} ${res.data.access_token}`)
            commit('setTokenData',res.data)
            dispatch('refreshToken')
          }
        })
      })
    },
    refreshToken({commit,state,dispatch}){
      let timeout = 0
      let refreshTimes = new Date().getTime()/1000
      const tokenData = cloneDeep(state.tokenData)
      window.clearInterval(refreshTokenTime)
      refreshTokenTime = null
      if(tokenData&&tokenData.expires_in){
        // timeout = timeout==0?3500:timeout
        timeout = timeout==0?divide(tokenData.expires_in,2):timeout
        refreshTimes+=timeout
        refreshTokenTime = window.setInterval(()=>{
          let times = new Date().getTime()/1000
          if(times>refreshTimes){
            window.clearInterval(refreshTokenTime)
            refreshTokenTime = null
            dispatch('resetToken')
          }
        },10*1000)
      }
    }
  },
  modules: {
  }
})
