import router from './router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import store from './store'
import {resetRouter} from './router'
const whiteList = ['/login','/report']

router.beforeEach(async(to,from,next)=>{
    console.log('router to',to.fullPath)
    NProgress.start()
    if(!store.getters.token){
        let isWhite = false
        whiteList.forEach(item => {
            if (to.path.indexOf(item)>-1) {
                isWhite = true
            }
        })
        if (isWhite) {
            NProgress.start()
            next()
        } else {
            NProgress.start()
            next({
                path: '/login',
                query: {
                    redirect: to.fullPath
                }
            })
        }
    }else{
        store.dispatch('refreshToken')
        const userInfo = store.getters.userInfo
        if(Object.keys(userInfo).length<1){
            store.dispatch('getUserInfo')
        }
        if(store.getters.isAddRoutes){
            next()
        }else{
            await store.dispatch('getPermission')
            const data = await store.dispatch('generateRoutes')
            resetRouter()
            if(data&&data.length>0){
                data.forEach((route:any)=>{
                    router.addRoute(route)
                })
            }
            store.commit('setIsAddRoutes',true)
            next({ ...to, replace: true })
        }
    }
    
})
router.afterEach(async(to,from,next)=>{
    NProgress.done()
    store.commit('setCacheStatus',true)
})