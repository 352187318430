import axios from 'axios';
const qs = require('qs');
import { __httpStatusCodeReg } from '@/utils/functions';
import store from '@/store';
import { ElLoading } from 'element-plus';
const service = axios.create({
  timeout: 60000
});
let loadingCount = 0;
let loadingInstance = null;
const loadingOpen = () => {
  loadingCount += 1;
  loadingInstance = ElLoading.service({
    fullscreen: true,
    lock: true,
    text: '加载中...',
    background: 'rgba(255,255,255,1)'
    // spinner:'yx'
  });
};

const loadingClose = () => {
  loadingCount -= 1;
  if (loadingCount < 1) {
    loadingCount = 0;
    if (loadingInstance) {
      loadingInstance.close();
    }
  }
};
//request拦截器
service.interceptors.request.use(config => {
  config.baseURL = config.baseURL ? config.baseURL : process.env.VUE_APP_BASE_API;
  config.headers['Content-Type'] = config.headers['Content-Type'] || "application/json; charset=utf-8";
  if (!config.headers['noToken'] && localStorage.getItem('token') && !config.headers['Authorization']) {
    config.headers['Authorization'] = localStorage.getItem('token');
  } else {
    delete config.headers['noToken'];
  }
  config.paramsSerializer = params => {
    return qs.stringify(params, {
      arrayFormat: 'repeat',
      skipNulls: true
    });
  };
  loadingOpen();
  return config;
}, error => {
  loadingClose();
  Promise.reject(error);
});
//response 拦截器
service.interceptors.response.use(res => {
  loadingClose();
  if (__httpStatusCodeReg(res.status)) {
    return res;
  } else {
    if (res?.data?.Message) {
      ElMessage({
        message: res?.data?.Message,
        type: 'error',
        duration: 2000
      });
    }
    return Promise.reject(res);
  }
}, error => {
  loadingClose();
  console.log('error', error);
  if (error?.response?.status == 401) {
    ElMessage({
      message: '请重新登录',
      type: 'error',
      duration: 2000
    });
    store.dispatch('signOut');
    setTimeout(function () {
      window.location.reload();
    }, 2000);
  } else if (error?.response?.data?.message) {
    ElMessage({
      message: error?.response?.data?.message,
      type: 'error',
      duration: 2000
    });
  } else {
    ElMessage({
      message: '请求出错，请重试',
      type: 'error',
      duration: 2000
    });
  }
  return Promise.reject(error);
});
export default service;