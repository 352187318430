import type { Directive } from 'vue'
import {__checkPermission} from '@/utils/functions'
import _ from 'lodash-es'
const permission = {
    mounted(el:any,binding:any,vnode:any) {
        if(!__checkPermission(binding.value)){
            el.parentNode&&el.parentNode.removeChild(el)
        }
    },
}
const resize = {
    mounted:function(el:any, binding:any){ // el为绑定的元素，binding为绑定给指令的对象
        const arg = binding.arg;
        const isWidth = arg=='height'?false:true,isHeight = arg=='width'?false:true;
        
        let width = '', height = '';
        function isReize() {
          const style:any = document?.defaultView?.getComputedStyle(el);
          if ((isWidth&&width !== style.width) || (isHeight&&height !== style.height)) {
            binding.value();  // 关键
          }
          width = style.width;
          height = style.height;
        }
        el.__vueSetInterval__ = setInterval(isReize, 300);
        // el.__resize__ = isReize
        // window.addEventListener('resize',el.__resize__)
    },
    unmounted(el:any) {
        clearInterval(el.__vueSetInterval__);
        // window.removeEventListener('resize',el.__resize__)
    }
}
const setValOfString = (val:any,isString=true)=>{
    let newVal:any = ''
    if(isString){
        newVal = val+''
        newVal.trim()
    }else{
        newVal = Number(val)
        newVal = isNaN(newVal)?'':newVal
    }
    return newVal
}
const number = {
    mounted:(el:any,binding:any)=>{
        // max：最大值，min：最小值，preLength：整数最大长度，suffLength：小数最大长度  positive 是否正数
        const { max, min, preLength = 7, suffLength = 0, zero = false,positive=false} = binding.value || {};
        let val:any = ''
        el.__vueSetInput__ = (e:any)=>{
            val = e.target.value.replace(/[^\d.]/g,'').replace(/\.{2,}/g,'.').replace(".","$#$").replace(/\./g,"").replace("$#$",".")
            if(isNaN(Number(val))){
                e.target.value = ''
                return;
            }
            if(max!=undefined&&Number(val)>max){
                e.target.value = max
                return;
            }
            if(min!=undefined&&Number(val)<min){
                e.target.value = min
                return;
            }
            if(!zero&&Number(val)==0){
                e.target.val = ''
                return;
            }
            val = val+''
            // 有长度限制时判断各个部分的长度
            const dotIndex = val.indexOf('.');
            let [lengthBefore, lengthAfter] = [0, 0];
            if (dotIndex == -1) {
                // 没有小数点 说明没小数部分
                lengthBefore = val.length;
            } else {
                lengthBefore = dotIndex;
                lengthAfter = val.length - dotIndex - 1;
            }
            console.log(
                val.substring(0,_.min([lengthBefore,preLength])),
                (dotIndex>-1&&suffLength>0?'.':''),
                val.substring(dotIndex+1,dotIndex+1+_.min([lengthAfter,suffLength]))
            )
            let newVal = val.substring(0,_.min([lengthBefore,preLength]))+(dotIndex>-1&&suffLength>0?'.':'')+val.substring(dotIndex+1,dotIndex+1+_.min([lengthAfter,suffLength]))
            e.target.value = positive?newVal.replace('-',''):newVal
        }
        el.addEventListener('input',el.__vueSetInput__,true)
    },
    unmounted:(el:any,binding:any)=>{
        // const inputEl = el.querySelector('input')||el
        el.removeEventListener('input',el.__vueSetInput__,true)
    }
}
const directives:{[propName:string]:Directive} = {
    permission,
    resize,
    number
}
export default directives