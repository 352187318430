import "core-js/modules/es.array.push.js";
const defaultAvatar = require('@/assets/images/user_avatar_default.png');
import { upload_file } from '@/api/base';
import store, { permissionFlat } from '@/store';
import dayjs from 'dayjs';
import { isString } from 'lodash-es';
export const __resetSearchItemObject = (data = [], type = 'array', labelKey = 'name') => {
  const rdata = data.reduce((arr, item) => {
    arr.push(item[labelKey]);
    return arr;
  }, []);
  if (type == 'string') {
    return rdata.join(',');
  } else {
    return rdata;
  }
};
export const __httpStatusCodeReg = function (statusCode, type = 'success') {
  const regData = {
    ['success']: /^2[0-9]{2}$/,
    ['fail']: /^4[0-9]{2}$/
  };
  return regData[type].test(statusCode);
};
//设置时间格式
export const __setTime = (date, formatter = 'YYYY-MM-DD HH:mm:ss', dateType = 'YYYY-MM-DD HH:mm:ss') => {
  return dayjs(date, dateType).format(formatter) || date;
};
//设置 ElTableColumn 无值时展示 '-'
export const __columnEmpty = (_row, _column, cellValue, _index) => {
  return cellValue || '-';
};
//获取图片
export const __setImg = id => {
  return id ? process.env.VUE_APP_BASE_API + '/api/files/private/' + id : defaultAvatar;
};
//设置头像
export const __setAvatar = url => {
  return url ? url : defaultAvatar;
};
//获取文件
export const __setFile = id => {
  return id ? process.env.VUE_APP_BASE_API + '/api/files/private/' + id : '';
};
export const __checkPermission = (permission = '') => {
  const permissionData = permissionFlat(store.getters.permission);
  if (Array.isArray(permission) && permission.length > 0) {
    return permissionData.some(p => {
      return permission.includes(p);
    });
  }
  if (isString(permission) && permission) {
    return permissionData.includes(permission);
  }
  return true;
};
export const __mobilePhoneReg = phone => {
  const myMobilePhoneReg = /^(((1[3-9]{1}))+\d{9})$/;
  return myMobilePhoneReg.test(phone);
};
export const __uploadFileFun = (e, size, accept) => {
  let allowFileType = accept ? accept.split(',') : [];
  let fileName = e.target.files[0].name;
  let nameData = fileName.toLowerCase().split('.');
  let fileSize = e.target.files[0].size;
  if (allowFileType && allowFileType.length > 0 && allowFileType.indexOf('.' + nameData[nameData.length - 1]) == -1) {
    ElMessage({
      message: '请上传' + allowFileType.join('、') + '格式的文件',
      duration: 2000,
      type: 'error'
    });
    e.target.value = '';
    return Promise.reject();
  }
  if (size && size > 0 && fileSize > size * 1024 * 1024) {
    ElMessage({
      message: '文件大小不能超过' + size + 'M',
      duration: 2000,
      type: 'error'
    });
    e.target.value = '';
    return Promise.reject();
  }
  return new Promise((resolve, reject) => {
    let fillData = [];
    if (Array.isArray(e)) {
      fillData = e;
    } else {
      fillData = e.target.files;
    }
    let formData = new FormData();
    formData.append("File", fillData[0]);
    upload_file(formData).then(res => {
      e.target.value = '';
      if (res.status == 200) {
        return resolve(res);
      } else {
        return reject();
      }
    }).catch(() => {
      e.target.value = '';
      return reject();
    });
  });
};
export const __numberToCN = num => {
  let changeNum = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九'];
  let unit = ["", "十", "百", "千", "万"];
  num = parseInt(num);
  let getWan = temp => {
    let strArr = temp.toString().split("").reverse();
    let newNum = "";
    for (var i = 0; i < strArr.length; i++) {
      newNum = (i == 0 && strArr[i] == 0 ? "" : i > 0 && strArr[i] == 0 && strArr[i - 1] == 0 ? "" : changeNum[strArr[i]] + (strArr[i] == 0 ? unit[0] : unit[i])) + newNum;
    }
    return newNum;
  };
  let overWan = Math.floor(num / 10000);
  let noWan = num % 10000;
  if (noWan.toString().length < 4) {
    noWan = "0" + noWan;
  }
  return overWan ? getWan(overWan) + "万" + getWan(noWan) : getWan(num);
};
export const __moneyFormat = function (money, zeroFill = true, emptyText) {
  if (money && money != null && money != ' ') {
    money = String(money);
    var left = money.split('.')[0],
      right = money.split('.')[1];
    if (zeroFill) {
      right = right ? right.length >= 2 ? '.' + right.substr(0, 2) : '.' + right + '0' : '.00';
    } else {
      right = right ? right.length >= 2 ? '.' + right.substr(0, 2) : '.' + right : '';
    }
    var temp = left.split('').reverse().join('').match(/(\d{1,3})/g);
    return (Number(money) < 0 ? '-' : '') + temp.join(',').split('').reverse().join('') + right;
  } else if (money === 0) {
    // 注意===在这里的使用，如果传入的money为0,if中会将其判定为boolean类型，故而要另外做===判断
    if (zeroFill) {
      return '0.00';
    } else {
      return 0;
    }
  } else {
    return emptyText || '';
  }
};
export const __sortByCode = function (data, key, type = '<') {
  return data.sort((a, b) => {
    if (type == '>') {
      return a[key] - b[key];
    } else {
      return b[key] - a[key];
    }
  });
};
export const __getNameOfCode = (code, data, vkey, lkey) => {
  let valueKey = vkey ? vkey : 'code';
  let labelKey = lkey ? lkey : 'name';
  if (code != undefined || code != null) {
    let obj = data.find(item => {
      return item[valueKey] == code;
    });
    return obj[labelKey] || '-';
  } else {
    return '';
  }
};
export const __hexToRgba = function (hex, opacity = 1) {
  return "rgba(" + parseInt("0x" + hex.slice(1, 3)) + "," + parseInt("0x" + hex.slice(3, 5)) + "," + parseInt("0x" + hex.slice(5, 7)) + "," + opacity + ")";
};