import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import '@/assets/font/icon/iconfont.css';
// import '@/assets/font/icon/my_iconfont.css';
// import '@/assets/font/icon/iconfont.js';

import globalComponents from '@/components/global/index'
import directives from '@/utils/directives'

import './permission' // permission control

import '@/styles/index.scss'

const app = createApp(App)
Object.keys(globalComponents).forEach(g => app.component(g, globalComponents[g]))
Object.keys(directives).forEach(d => app.directive(d, directives[d]))
app.use(store)
app.use(router)
app.mount('#app')
