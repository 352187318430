import request from '@/utils/request'
//获取所有权限集合
export function permission_list(){
    return request({
        url:'/api/identity/permission',
        method:'get'
    })
}
//获取图片
export function getImgOfId(id:any){
    return request({
        url:'/api/files/private/'+id,
        method:'GET',
    })
}
//获取登录用户信息
export function get_user_info(){
    return request({
        url:'/api/staff/info',
        method:'GET'
    })
}
//上传文件
export function upload_file(formData:any){
    return request({
        url:'/api/files/private',
        method:'POST',
        headers:{
            'Content-Type':'multipart/form-data'
        },
        data:formData
    })
}
//发送手机验证码
export function send_smscode(phone:any,data:any){
    return request({
        url:'/api/smscode/'+phone,
        headers:{
            'content-type':'application/x-www-form-urlencoded'
        },
        method:'POST',
        data
    })
}
//获取省市区
export function getAddress(code:any){
    return request({
        url:'/api/common/address/'+code,
        method:'GET'
    })
}
//保存文件信息
export function save_file(data:any){
    return request({
        url:'/api/files/private/save',
        method:'POST',
        data
    })
}
//获取文件路径
export function get_file_url(id:any){
    return request({
        url:'/api/files/private/basic-file/'+id,
        method:'GET'
    })
}